import React from 'react'
import './Pagina6.css'

import Bil1 from "../../assets/05guayabitos1.jpg";
import Bil2 from "../../assets/05guayabitos2.jpg";
import Bil3 from "../../assets/05guayabitos3.jpg";
import Bil4 from "../../assets/05guayabitos4.jpg";
import Bil5 from "../../assets/05guayabitos5.jpg";

const Pagina6 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Rincón de Guayabitos</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos5">
            
            Rincón de Guayabitos es una perla escondida en las playas de Nayarit. Su playa de olas es parte de la Bahía de Jaltemba, enmarcada por la belleza arquitectónica de conjuntos residenciales, restaurantes, y centros comerciales, que son muy visitados por el turismo nacional y extranjero.<br/><br/>
Abarca 2 kilómetros de longitud por 30 metros de ancho, es de olas tranquilas y tibias todo el año; ideal para practicar deportes acuáticos como son: canotaje, remo, esquí, surfing, veleo, esnorquel y otros.<br/><br/>
Guayabitos forma parte de la zona conurbada compuesta también por La Peñita de Jaltemba y Los Ayala. Una bahía famosa por su semejanza a una gigantesca alberca, la playa de fina y aperlada arena de este destino se extiende por dos kilómetros de largo y 30 metros de ancho, es bañada por un tranquilo y cristalino mar. Su vegetación tropical, temperatura templada y oleaje ideal para la práctica de diversos deportes acuáticos han hecho de este un destino familiar por excelencia.<br/><br/>
El desarrollo de Guayabitos comenzó hace 30 años con la construcción de hoteles, zonas residenciales y diversos comercios. Este fraccionamiento turístico además de contar con excelentes servicios de hospedaje, alimentos y áreas comerciales; le ofrece al visitante recorridos turísticos donde podrá disfrutar del mar, la arena y el sol, además de maravillarse con los delfines y ballenas que vienes durante los meses de noviembre y marzo.El desarrollo de Guayabitos comenzó hace 30 años con la construcción de hoteles, zonas residenciales y diversos comercios. Este fraccionamiento turístico además de contar con excelentes servicios de hospedaje, alimentos y áreas comerciales; le ofrece al visitante recorridos turísticos donde podrá disfrutar del mar, la arena y el sol, además de maravillarse con los delfines y ballenas que vienes durante los meses de noviembre y marzo.<br/><br/>
 </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
    <div className="first-des vision">
                <div className="des-text">
                    <h2></h2>
                    <p className="textos5">
Así que las opciones para alojarse aquí abarcan todas las posibilidades: desde hoteles con todo incluido, bungalows, desarrollos frente al mar, hasta estancias ecológicas.<br/>
De igual manera las posibilidades gastronomicas son variadas: desde la típica comida mexicana, pescado zarandeado y una gran variedad de antojos como pan de coco, nieves raspadas, plátanos fritos y platillos preparados con yaca, una fruta tropical típica de la zona. No se olvide también de saborear una típica Piña Loca, antojo que combina esta fruta con chile en polvo y jarabe dulce.<br/><br/>
No olvides visitar las Islas Coral y del Cangrejo, emblemas del destino, aquí encontrarás magníficos paisajes naturales, y podrás avistar aves como pelícanos, gaviotas, pájaros bobos y muchos más. Te recomendamos bucear o practicar snorkel en sus hermosas aguas color turquesa. <br/><br/>

Después de disfrutar tu día en la playa, camina por calle principal de Guayabitos, donde encontrarás gran variedad de tiendas para comprar recuerditos, artesanías o increíbles piezas de arte Huichol, joyería de plata, decoraciones y demás curiosidades de la región. <br/><br/>
Déjate sorprender por el color, tradición y la amabilidad de la gente de Guayabitos, ¿sabías que Guayabitos recibe su nombre del árbol de guayaba que crece en estas tierras?.<br/><br/>
Este bello pueblo fue descubierto por los españoles y visitado por los galeones ingleses y holandeses.
            </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>  
</div>
  )
}

export default Pagina6
