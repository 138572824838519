import React from 'react'
import './Pagina5.css'

import Bil1 from "../../assets/04plazalape_ita.jpg";
import Bil2 from "../../assets/plaza.jpg";

const Pagina5 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Plaza principal La Peñita</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos4">
            Otra parada importante es visitar la plaza principal de la peñita y disfrutar de las vistas del kiosco y las pinturas de artistas locales que decoran la plaza principal, la plaza ha sido renovada recientemente por lo que te recomendamos llevar tu cámara fotográfica ya que vale la pena tomarse o tomar una foto aquí.<br/><br/>
Atracciones adicionales para visitar en La Peñita:<br/><br/>
• La iglesia y la Plaza<br/>
• Lienzo Charro<br/>
• Mercado del mar<br/>
• La Cruz La Virgen<br/>
• El estero<br/>
• El “Puente de la vida”

            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
</div>
  )
}

export default Pagina5
