import React,{useEffect} from 'react'
import "./offers.css"

import {MdKingBed, MdLocationOn} from 'react-icons/md';
import {MdBathtub} from 'react-icons/md';
import {FaWifi} from 'react-icons/fa';
import {MdAirportShuttle} from 'react-icons/md';
import {BsArrowRightShort} from 'react-icons/bs'

import Aos from 'aos';
import 'aos/dist/aos.css';

//Imagenes
import img from "../../assets/aver.jpg"
import img11 from "../../assets/suite4p.jpg"
import img12 from "../../assets/suite5p.jpg"
import img13 from "../../assets/suite6p.jpg"
import img14 from "../../assets/suite7p.jpg"
import img15 from "../../assets/suite8p.jpg"
import img16 from "../../assets/amenity03.jpg"

import { Link } from 'react-router-dom';

//Agregar y ordenar imagenes por medio de array

const Offerst = [
  {
    id:1,
    imgSrc: img11,
    destTitle: 'Playa Chicos Paradise',
    location: 'La Peñita de Jaltemba',
    cama: "1 cama queen size",
    sofacama: "1 Sofacama matrimonial",
    price: '$1,200 por noche.',
    personas: '4 personas',
    info: "/habitacion-de-4",
    flecha: "Reservar",
    enlace: "https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates",
  },
  {
    id:2,
    imgSrc: img12,
    destTitle: 'Playa Guayabitos',
    cama: "1 cama king size",
    sofacama: "1 Sofacama matrimonial",
    location: 'La Peñita de Jaltemba',
    price: '$1,500 por noche.',
    personas: '5 personas',
    info: "/habitacion-de-5",
    flecha: "Reservar",
    enlace: "https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates",
  },
  {
    id:3,
    imgSrc: img13,
    destTitle: 'Alberca Guayabitos',
    cama: "2 camas matrimoniales",
    sofacama: "1 Sofacama matrimonial",
    location: 'La Peñita de Jaltemba',
    price: '$1,800 por noche',
    personas: '6 personas',
    info: "/habitacion-de-6",
    flecha: "Reservar",
    enlace: "https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates",
  },
  {
    id:4,
    imgSrc: img14,
    destTitle: 'Playa Guayabitos',
    cama: "1 cama king size",
    sofacama: "2 Sofacamas matrimoniales",
    location: 'La Peñita de Jaltemba',
    price: '$2,000 por noche.',
    personas: '7 personas',
    info: "/habitacion-de-7",
    flecha: "Reservar",
    enlace: "https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates",
  },
  {
    id:5,
    imgSrc: img15,
    destTitle: 'Alberca Guayabitos',
    cama: "2 camas matrimoniales",
    sofacama: "2 Sofacamas matrimoniales",
    location: 'La Peñita de Jaltemba',
    price: '$2,300 por noche',
    personas: '8 personas',    
    info: "/habitacion-de-8",
    flecha: "Reservar",
    enlace: "https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates",
  },
  {
    id:6,
    imgSrc: img16,
    destTitle: 'Alberca Guayabitos',
    cama: "",
    sofacama: " ",
    location: 'La Peñita de Jaltemba',
    price: 'Áreas comunes',
    personas: '',    
    info: "/areas-comunes",
    flecha: "Ver detalles",
    enlace: "/areas-comunes",
  },
]

const Offers = () => {
  
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <section className='offer container section' id='suites'>
      <div className='secContainer'>

        <div data-aos="fade-up" data-aos-duration="2000" className='secIntro'>
          <h2 className='secTitle'>
            Suites
          </h2>
          <p>
            Disfruta de increíbles amaneceres desde la comodidad de tu habitación...
          </p>
        </div>
        
        <div className='mainContent grid'>

          {Offerst.map(({id, imgSrc, destTitle, location, price, personas, cama, sofacama, info, flecha, enlace}) =>{
            return(
              <div data-aos="fade-up" data-aos-duration="3000" className='singleOffer'>
            <div className='destImage'>
              <img src={imgSrc} alt={destTitle}/>

              <span className='discount'>
                {personas}
              </span>
            </div>

            <div className='offerBody'>
              <div className='price flex'>
                <h4>
                  {price}
                </h4>
                <Link to={info} target='_blank'>
                <span className='status'>
                  Ver más
                </span>
                </Link>
              </div>

              <div className='amenities flex'>
                <div className='singleAmenity flex'>
                  <MdKingBed className="icon"/>
                  <small>{cama}</small>
                </div>                
                <div className='singleAmenity flex'>
                  <MdBathtub className="icon"/>
                  <small>{sofacama}</small>
                </div>
                <div className='singleAmenity flex'>
                  <FaWifi className="icon"/>
                  <small>Wi-fi</small>
                </div>
              </div>

              <div className='location flex'>
                <MdLocationOn className='icon'/>
                <small>{location}.</small>
              </div>
              <Link to={enlace} target="_blank" >
              <button className='btn flex'>
                {flecha}
                <BsArrowRightShort className="icon"/>
              </button>
              </Link>
            </div>
          </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Offers
