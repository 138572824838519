export const data = [
    {
        id: 1, imgUrl: "https://suitesdelcarmen.com.mx/amenity01.jpg"
    },
    {
        id: 2, imgUrl: "https://suitesdelcarmen.com.mx/amenity02.jpg"
    },
    {
        id: 3, imgUrl: "https://suitesdelcarmen.com.mx/amenity03.jpg"
    },
    {
        id: 4, imgUrl: "https://suitesdelcarmen.com.mx/amenity04.jpg"
    },
    {
        id: 5, imgUrl: "https://suitesdelcarmen.com.mx/amenity05.jpg"
    },
    {
        id: 6, imgUrl: "https://suitesdelcarmen.com.mx/amenity06.jpg"
    },
    {
        id: 8, imgUrl: "https://suitesdelcarmen.com.mx/amenity08.jpg"
    },
    {
        id: 9, imgUrl: "https://suitesdelcarmen.com.mx/amenity09.jpg"
    },
    {
        id: 10, imgUrl: "https://suitesdelcarmen.com.mx/amenity10.jpg"
    }
]