import React,{useEffect} from 'react'
import "./habitaciones.css"

import Aos from 'aos';
import 'aos/dist/aos.css';

//Imagenes
import imagen1 from "../../assets/dos.jpg";
import imagen2 from "../../assets/cuatro.jpg";
import imagen3 from "../../assets/tres.jpg";
//Video
import video from "../../assets/video_habitaciones.mp4";
const About = () => {
  
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <section className='about section galeria' id='galeria'>
      <div className='secContainer'>

        <div className='videoCard2 container'>
          <div className='cardContent grid'>
            <div data-aos="fade-right" data-aos-duration="2000" className='cardText2'>
              <h2>
              Conoce nuestras habitaciones...
              </h2>
              <p>
              Nuestras instalaciones y habitaciones están diseñadas para ofrecerte el máximo confort y comodidad. Contamos con wifi, aire acondicionado, televisión, minibar y mucho más. Ven y descubre todo lo que tenemos para ti.
               </p>
            </div>

            <div data-aos="fade-left" data-aos-duration="2000" className='cardVideo'>
              <video src={video} autoPlay loop muted type="video/mp4"></video>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About
