import React from 'react'
import Info1 from './Info1'
import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/suites_barra.jpg"

const Room1 = () => {
  return (
    <div>
    <Barra
      cName="hero-mid"
      heroImg={QuienesImg}
      title=""        
      text=""
      btnClass="hide"/>
      <Info1/>
    </div>
  )
}

export default Room1
