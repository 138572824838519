import React from 'react'

import Bil1 from "../../assets/suite5p_1.jpg";
import Bil2 from "../../assets/suite5p_3.jpg";
import Info6 from './Info6';
const Info2 = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Habitacion para 5 personas</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            Esta espaciosa habitación está diseñada para acomodar a un grupo de cinco personas con total comodidad. En el centro de la habitación se encuentra una lujosa cama king size, con sábanas y edredones suaves que prometen un sueño reparador. Junto a la cama, un sofá cama matrimonial ofrece espacio adicional para el descanso, brindando flexibilidad en la configuración para alojar a todos los huéspedes.<br/><br/>

La habitación está decorada con colores cálidos y relajantes, creando un ambiente acogedor y agradable. Amplias ventanas permiten la entrada de luz natural y ofrecen vistas atractivas. La conectividad es una prioridad, ya que la habitación está equipada con Wi-Fi, asegurando que los huéspedes puedan mantenerse en línea y conectados durante su estancia.<br/><br/>

También se proporciona un espacio de trabajo funcional con un escritorio y una silla, ideal para aquellos que necesitan trabajar o estudiar durante su visita. Un televisor de pantalla plana montado en la pared ofrece opciones de entretenimiento para momentos de relajación. En conjunto, esta habitación ofrece una combinación perfecta de comodidad, estilo y funcionalidad, garantizando una estancia agradable para un grupo de cinco personas.
            </p>
            </div>
            <div className='margen'>
        <Info6/>

            </div>
    </div>       
</div>
  )
}

export default Info2
