import React from 'react'
import './Pagina9.css'

import Bil1 from "../../assets/08playadeltoro1.jpg";
import Bil2 from "../../assets/08playadeltoro2.jpg";
import Bil3 from "../../assets/08playadeltoro3.jpg";
import Bil4 from "../../assets/08playadeltoro4.jpg";

const Pagina9 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe"> Playa/Mirador del Toro</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos8">
            La Playa del Toro es una gema secreta de Riviera Nayarit, su agua es cristalina, arena fina, sin oleaje y enmarcada por unas palmeras que te brindarán el mejor clima de la región. Nadar aquí es una delicia, además regularmente se encuentra poco concurrida, el acceso puede ser por lancha o por el recorrido que te narramos arriba. Cuéntanos en comentarios si has estado aquí.<br/><br/>

Descubre el Mirador del Toro <br/><br/>

En Riviera Nayarit, Mirador del Toro, un acantilado desde el cual podrás tener la vista más increíble que puedes imaginarte.<br/><br/>

¿Cómo llegar al Mirador del Toro?<br/><br/>
El Mirador del Toro se ubica al Norte de Riviera Nayarit, a un costado de Los Ayala, aproximadamente a 10 minutos del Bello Rincón de Guayabitos, en el paisaje de fondo podrás disfrutar de la maravillosa vista de Isla Coral, un paraíso natural encantador.

            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
    <div className="first-des vision">
                <div className="des-text">
                    <h2></h2>
                    <p className="textos8">
                    El hike hacia el Mirador del Toro es muy bello y emocionante, este comienza al final de la playa (a tu izquierda) con subidas y bajadas; la mejor parte es que caminas por un costado de playas vírgenes y secretas como lo son: Frideras y Frideritas. No te preocupes por la ruta, está marcada por señales que te ayudarán a guiarte.<br/><br/>

¿Cuánto dura el hike del Mirador del Toro?<br/><br/>
El recorrido es muy relajante y fresco, está lleno de vegetación y de imponentes palmeras que adornan el camino, su grado de dificultad es bajo y se recomienda para toda la familia. Su duración es aproximadamente de 50 minutos. Ten cuidado con los animales, en el camino vimos unos coatis y aves. Además, te recordamos respetar la flora y fauna del lugar, ya que este es el hogar de muchas especies naturales.<br/><br/> 

Casi al terminar el hike te encontrarás una señal que indica hacia donde está el mirador y el camino de la playa, te recomendamos ir hacia el mirador y después bajar a la playa, la cual lleva el mismo nombre: Playa del Toro.<br/><br/>

Se cuidadoso al pisar entre las rocas del mirador, asegúrate de llevar calzado deportivo, ropa cómoda, agua para hidratarte y tu celular para tomar muchas selfies.

            </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>  
</div>
  )
}

export default Pagina9
