import React from 'react'

import Bil1 from "../../assets/suite8p_1.jpg";
import Bil2 from "../../assets/suite8p_3.jpg";
import Galeria5 from './Galeria5';

const Info5 = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Habitacion para 8 personas</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            Esta espaciosa y versátil habitación es ideal para grupos grandes de hasta ocho personas. Dos lujosas camas king size son el punto focal de la habitación, con sábanas suaves y almohadas mullidas que garantizan un descanso revitalizante. Además, dos cómodos sofás cama matrimoniales ofrecen opciones adicionales para el alojamiento, brindando flexibilidad en la configuración y asegurando una estadía placentera para todos los huéspedes.<br/><br/>

La habitación está diseñada con un enfoque en la comodidad y el estilo, con colores relajantes que crean un ambiente tranquilo y acogedor. Amplias ventanas permiten la entrada de luz natural, realzando el ambiente luminoso de la habitación. La conectividad es clave, y la habitación ofrece acceso a Wi-Fi para mantener a todos los huéspedes conectados durante su estancia.<br/><br/>

Un espacio de trabajo funcional con escritorio y silla está disponible para aquellos que necesitan trabajar o estudiar. Además, un televisor de pantalla plana montado en la pared brinda opciones de entretenimiento para relajarse después de un día lleno de actividades.<br/><br/>

En resumen, esta habitación ofrece una generosa cantidad de espacio, comodidad y conectividad para grupos numerosos, garantizando una experiencia agradable y satisfactoria para todos los huéspedes.
            </p>
            </div>
        <div className="margen">
          <Galeria5/>
        </div>
    </div>       
</div>
  )
}

export default Info5
