import React from 'react'

import Bil1 from "../../assets/suite6p_1.jpg";
import Bil2 from "../../assets/suite6p_3.jpg";
import Galeria3 from './Galeria3';

const Info3 = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Habitacion para 6 personas</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            Esta espaciosa y versátil habitación es perfecta para grupos de hasta seis personas. Ofrece dos cómodas camas matrimoniales con ropa de cama suave y almohadas acogedoras, garantizando un descanso revitalizante para todos los huéspedes. Además, un amplio sofá cama matrimonial añade una opción adicional para el alojamiento, asegurando flexibilidad en la configuración para adaptarse a las necesidades del grupo.<br/><br/>

La habitación está elegantemente decorada con colores relajantes y detalles modernos. Grandes ventanas permiten la entrada de luz natural, creando un ambiente luminoso y agradable. La conectividad es esencial, y la habitación ofrece Wi-Fi para mantener a todos los huéspedes conectados durante su estancia.<br/><br/>
<br/>
Para aquellos que necesitan trabajar o estudiar, se proporciona un área de escritorio funcional con una silla cómoda. Un televisor de pantalla plana montado en la pared brinda opciones de entretenimiento para momentos de relajación.<br/><br/>

En conjunto, esta habitación ofrece una combinación perfecta de espacio, comodidad y conectividad para grupos de hasta seis personas, asegurando una estancia agradable y placentera para todos.
</p>
            </div>
        <div className="margen">
          <Galeria3/>
        </div>
    </div>       
</div>
  )
}

export default Info3
