import React from 'react'
import './Info1.css'

import Bil1 from "../../assets/suite4p_1.jpg";
import Bil2 from "../../assets/suite4p_3.jpg";
import Galeria1 from './Galeria1';

const Info1 = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Habitacion para 4 personas</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            Esta habitación es ideal para acomodar a cuatro personas con comodidad. Presenta una acogedora cama queen size con sábanas suaves y almohadas mullidas en un rincón, 
            brindando un espacio para un descanso reparador. Junto a la cama, hay un sofá cama matrimonial que se convierte en una cama adicional para dos personas, ofreciendo 
            flexibilidad en el alojamiento. La decoración es moderna y relajante, con tonos neutros en las paredes y toques de color en los detalles decorativos. La habitación 
            cuenta con amplias ventanas que permiten la entrada de luz natural, creando un ambiente luminoso y agradable. Un escritorio funcional proporciona un espacio para 
            trabajar o planificar el día. Además, hay un televisor montado en la pared para entretenimiento. En conjunto, esta habitación ofrece una estancia cómoda y agradable 
            para cuatro personas.</p>
            </div>
        <div className="margen">
          <Galeria1/>
        </div>
    </div>       
</div>
  )
}

export default Info1
