import React,{useEffect} from 'react';
import "./popular.css";

import {BsArrowLeftShort, BsDot} from 'react-icons/bs';
import {BsArrowRightShort} from 'react-icons/bs';

import Aos from 'aos';
import 'aos/dist/aos.css';

//Importar las imagenes
import Imagen from "../../assets/collage_1.jpg"
import Imagen2 from "../../assets/atractivos1.jpg"
import Imagen3 from "../../assets/atractivos2.jpg"
import Imagen4 from "../../assets/atractivos3.jpg"
import Imagen5 from "../../assets/atractivos4.jpg"
import Imagen6 from "../../assets/atractivos5.jpg" 
import Imagen7 from "../../assets/atractivos6.jpg"
import Imagen8 from "../../assets/atractivos7.jpg"
import Imagen9 from "../../assets/atractivos8.jpg"
import Imagen10 from "../../assets/08playadeltoro4.jpg";

import { Link } from 'react-router-dom';
///Metodo de ordenamiento de array usando Map para agregar todas las imagenes.

const Data = [
  {
    id: 1,
    imgSrc: Imagen2,
    destTitle: 'Puerto Vallarta',
    location: 'La Peñita de Jaltemba',
    grade: 'Suites del Carmen',
    ruta: "/la-peñita-de-jaltemba",
  },
  {
    id: 2,
    imgSrc: Imagen3,
    destTitle: 'Puerto',
    location: 'Malecón la Peñita',
    grade: 'Suites del Carmen',
    ruta: "/malecon-la-peñita",
  },
  {
    id: 3,
    imgSrc: Imagen4,
    destTitle: 'Vallarta',
    location: 'Tianguis La Peñita',
    grade: 'Suites del Carmen',
    ruta: "/tianguis-la-peñita",
  },
  {
    id: 4,
    imgSrc: Imagen5,
    destTitle: 'Puerto Vallarta',
    location: 'Plaza principal La Peñita',
    grade: 'Suites del Carmen',
    ruta: "/plaza-principal-la-peñita",
  },
  {
    id: 5,
  imgSrc: Imagen7,
    destTitle: 'Puerto Vallarta',
    location: 'Rincón de Guayabitos',
    grade: 'Suites del Carmen',
    ruta: "/rincon-de-guayabitos",
  },
  {
    id: 6,
    imgSrc: Imagen8,
    destTitle: 'Puerto',
    location: 'Playa Los Ayala',
    grade: 'Suites del Carmen',
    ruta: "/playa-los-ayala",
  },
  {
    id: 7,
    imgSrc: Imagen9,
    destTitle: 'Vallarta',
    location: 'Isla del Coral',
    grade: 'Suites del Carmen',
    ruta: "/isla-del-coral",
  },
  {
    id: 8,
    imgSrc: Imagen10,
    destTitle: 'Puerto Vallarta',
    location: 'Playa/Mirador del Toro',
    grade: 'Suites del Carmen',
    ruta: "/playa-mirador-del-toro",
  }
]

const Popular = () => {

  useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <section className='popular section container' id='atractivos'>
      <div className="secContainer">

        <div className="secHeader flex">
          <div data-aos="fade-right" data-aous-duration="2500" className="textDiv">
            <h2 className="secTitle">
            Atractivos
            </h2> 
            <p>
            Conoce lugares de playa y descanso que puedes disfrutar en nuestra bahía de Jaltemba.
            </p>
          </div>

          <div data-aos="fade-left" data-aous-duration="2500" className="iconsDiv flex">
            <BsArrowLeftShort className="icon leftIcon"/>
            <BsArrowRightShort className="icon"/>
          </div>
        </div>

        <div className='mainContent grid'>
        {Data.map(({id, imgSrc, destTitle, location, grade, ruta}) => {
          return(
            <div data-aos="fade-up" className='singleDestination'>
            <div className='destImage'>
              <img src={imgSrc} alt={location}/>

              
              <Link to={ruta} target="_blank" >
              <div className='overlayInfo'>
                <h3>{location}</h3>
                <p>
                </p>

                <BsArrowRightShort className='icon'/>
                
              </div>
              </Link>

            </div>

            <div className='destFooter'>
              <div className='number'>
                0{id}
              </div>

              <div className='destText flex'>
                <h6>
                  {location}
                </h6>
                <span className='flex'>
                  <span className='dot'>
                    <BsDot className='icon'/>
                  </span>
                </span>
              </div>
            </div>

          </div>
          )})}
        </div>

      </div>
    </section>
  )
}

export default Popular;
