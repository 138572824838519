import React from 'react'
import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/atractivos_barra.jpg"
import Pagina2 from "./Pagina2"
const Lugar1 = () => {
  return (
    <>
    <Barra
      cName="hero-mid"
      heroImg={QuienesImg}
      title=""        
      text=""
      btnClass="hide"/>
      <Pagina2/>
    </>
  )
}

export default Lugar1
