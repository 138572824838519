import React, {useState} from 'react'
import "./navbar.css"
//Iconos
import {SiYourtraveldottv} from 'react-icons/si';
import {AiFillCloseCircle} from 'react-icons/ai';
import {TbGridDots} from 'react-icons/tb';
import {Link, ScrollLink} from "react-scroll";
import logi from "../../assets/logo_sdc2.png"

const Navbar = () => {

  const [active, setActive] = useState('navBar');
  const showNav = () => {
    setActive("navBar activeNavbar");
  }
  //Remover la navbar
  const removeNav = () => {
    setActive("navBar");
  }
  //Anadir color de fondo al header....
  const [transparent, setTransparent] = useState('header');
  const addBg = () =>{
    if(window.scrollY >= 10){
      setTransparent("header activeHeader")
    }
    else{
      setTransparent("header")
    }
  }
  window.addEventListener('scroll', addBg)

  return (
    <section className='navBarSection'>
    <div className={transparent}>

        <div className="logoDiv">
          <a href='#' className="logo">
            <h1 className='flex'><img src={logi} alt="Image Name" className='icon'/>
              
            </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className='navItem'>
              <a href='#' className='navLink'>Inicio</a>
            </li>
            <li className='navItem'>
              <a href='#suites' className='navLink'>Suites</a>
            </li>
            <li className='navItem'>                 
              <a href='#galeria' className='navLink'>Galería</a>
            </li>
            <li className='navItem'>
              <a href='#atractivos' className='navLink'>Atractivos</a>
            </li>
            <li className='navItem'>
              <a href='#contacto' className='navLink'>Contacto</a>
            </li>

            <div className='headerBtns flex'>
              <button className='btn loginBtn'>
                <a href='https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates' target='_blank'>¡Reserva ya!</a>
              </button>
            </div>
          </ul>

          <div onClick={removeNav} className='closeNavBar'>
            <AiFillCloseCircle className='icon'/>
          </div>
        </div>

        <div onClick={showNav}
        className="toogleNavBar" >
          <TbGridDots className="icon"/>
        </div>
        
      </div>
    </section>
  )
}

export default Navbar
