import React from 'react'
import './Pagina4.css'

import Bil1 from "../../assets/03TianguisLape_ita.jpg";
import Bil2 from "../../assets/tianguis.jpg";
const Pagina4 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Tianguis La Peñita de Jaltemba</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos3">
            Todos los jueves hay un tianguis por las calles de la peñita en la que comerciantes de todas las zonas vienen a vender sus productos y los locales y turistas visitan este mercado en el que puedes comprar productos, artesanías, frutas y verduras de la región o probar platillos típicos.
             Cada febrero, se celebra un carnaval que llena las calles de música, desfiles y mucha fiesta.<br/><br/>
Otra de las costumbres arraigadas es el tianguis que cada jueves se levanta en las calles de La Peñita para ofrecer a los visitantes artesanías, joyería de plata y comida mexicana.
<br/><br/>Atracciones<br/><br/>
Cada febrero, se celebra un carnaval que llena las calles de música, desfiles y mucha fiesta.<br/>
Otra de las costumbres arraigadas es el tianguis que cada jueves se levanta en las calles de La Peñita para ofrecer a los visitantes artesanías, joyería de plata y comida mexicana.

            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>       
</div>
  )
}

export default Pagina4
