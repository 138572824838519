import React from 'react'
import "./barra.css";
import {Link} from "react-scroll";

function Barra(props) {
  return (
    <>
    <div className={props.cName}>
        <img alt="HerpImg" 
        src={props.heroImg}/>
    </div>
    <Link className="menu" to="trip" spy={true} smooth={true} offset={-100} duration={500} > 
    <div className="hero-text">
      <h1>{props.title}</h1>
      <p>{props.text}.</p>
      <Link className={props.btnClass} to="trip" spy={true} smooth={true} offset={-100} duration={500} >                        
                         {props.buttonText}
                        </Link>
    </div>
    </Link>
    </>
  )
}

export default Barra
