import React from 'react'

import QuienesImg from "../../assets/atractivos_barra.jpg"
import Barra from '../Barra/Barra'
import Pagina8 from './Pagina8'
const Lugar7 = () => {
  return (
    <>
      
     <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
        <Pagina8/>
    </>
  )
}

export default Lugar7
