import React from 'react'


import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/areas_barra.jpg"
import InfoAreas from './InfoAreas'
const AreasComunes = () => {
  return (
    <>   
     
    <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
        <InfoAreas/>
    </>
  )
}

export default AreasComunes
