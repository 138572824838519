import React from 'react'
import QuienesImg from "../../assets/atractivos_barra.jpg"
import Barra from '../Barra/Barra'
import Pagina7 from './Pagina7'

const Lugar6 = () => {
  return (
    <>
      
     <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
        <Pagina7/>
    </>
  )
}

export default Lugar6
