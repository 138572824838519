import React,{useEffect} from 'react';
import "./home.css";

import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

  useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <section className='home'>
      <div className='secContainer container'>
        <div className='homeText'>
          <h1 data-aos="fade-up" className='title'>
          </h1>
          <p data-aos="fade-up" data-aos-duration="2500" className='subTitle'>
           Despierta cada mañana con la brisa marina.
          </p>
          <a href='https://app.reservatio.com.mx/suites-del-carmen/book#/choose-dates' target='_blank'>
          <button data-aos="fade-up" data-aos-duration="3000" className='btn'>
          Reserva ahora
            </button>
            </a>
          
        </div>

      </div>
    </section>
  )
}

export default Home
