import React,{useEffect} from 'react'
import "./footer.css"

import {SiYourtraveldottv} from "react-icons/si";
import {ImFacebook, ImWhatsapp} from 'react-icons/im';
import {BsTwitter} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import logis from "../../assets/logo_suites3.png"

import Aos from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
  
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <section className='footer' id='contacto'>
      <div className="secContainer container grid">
        <div data-aos="fade-up" data-aos-duration="2000" className="logoDiv">

          <div data-aos="fade-up" data-aos-duration="2000" className="footerLogo">
            <a href="#" className="logo flex">
              <h1 className='flex'><img src={logis} className="icon"/></h1>
            </a>
          </div>

          <div className="socials flex">
            
          <a href='https://www.facebook.com/suitesdelcarmen/' target='_blank'>          
          <ImFacebook className='icon'/>
          </a>
          <a href='https://www.instagram.com/suitesdelcarmen/' target='_blank'>   
          <AiFillInstagram className='icon'/>
          </a>
          <a href='https://wa.link/d3nh72' target='_blank'>   
          <ImWhatsapp className='icon'/>
          </a>
          
          </div>

        </div>

        <div data-aos="fade-up" data-aos-duration="3000" className='footerLinks'>
          <span className='linkTitle'>
            Informacion
          </span>
          <li>
            <a href='#'>Inicio</a>
          </li>
          <li>
            <a href='#'>Explorar</a>
          </li>
          <li>
            <a href='#'>Planes de viaje</a>
          </li>
          <li>
            <a href='#'>Noticias</a>
          </li>
        </div>

        <div data-aos="fade-up" data-aos-duration="4000" className='footerLinks'>
          <span className='linkTitle'>
            Ayuda
          </span>
          <li>
            <a href='#'>Lugares</a>
          </li>
          <li>
            <a href='#'>Soporte</a>
          </li>
          <li>
            <a href='#'>Viajes y condiciones</a>
          </li>
          <li>
            <a href='#'>Privacidad</a>
          </li>
        </div>

        <div className='footerLinks'>
          <span className='linkTitle'>
            Contactanos
          </span>
          <span className='phone'>+52 322 289 1355</span>
          <span className='email'>suitesdelcarmen@hotmail.com</span>
        </div>

        

      </div>
    </section>
  )
}

export default Footer
