import React from 'react'

import Info5 from './Info5'
import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/suites_barra.jpg"
const Room5 = () => {
  return (
    <div>
    <Barra
      cName="hero-mid"
      heroImg={QuienesImg}
      title=""        
      text=""
      btnClass="hide"/>
      <Info5/>
    </div>
  )
}

export default Room5
