import React from 'react'

import Info3 from './Info3'
import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/suites_barra.jpg"
const Room3 = () => {
  return (
    <div>
    <Barra
      cName="hero-mid"
      heroImg={QuienesImg}
      title=""        
      text=""
      btnClass="hide"/>
      <Info3/>
    </div>
  )
}

export default Room3
