import React from 'react'
import './Pagina7.css'

import Bil1 from "../../assets/06losayala1.jpg";
import Bil2 from "../../assets/06losayala2.jpg";
import Bil3 from "../../assets/06losayala3.jpg";
import Bil4 from "../../assets/06losayala4.jpg";
const Pagina7 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Playa Los Ayala</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos6">
            Pequeño poblado que se encuentra hacia el sur de Rincón de Guayabitos. Un lugar de leyenda, ya que se dice, su nombre lo tomaron del apellido de unos bandidos que azotaron la zona en la época del cacicazgo.<br/><br/>
Hacia el norte de este sitio, existen unas pequeñas cuevas escondidas por la densa maleza del lugar, y las cuales eran utilizadas para escabullirse de los hacendados y con ello ganarse fama de que el sitio estaba embrujado, por lo que nadie se atrevía a entrar a esta zona que denominaron “Los Ayala”.<br/><br/>
Playa los Ayala y Frideras: Encantadoras playas de arena dorada y textura fina, sus aguas tienen tonalidades que varían de azul al verde y su alrededor está perfectamente bien complementado con su predominante vegetación.<br/><br/>
Aquí podrá pasar un día lleno de aventura y tomar un bronceado envidiable. Se ofrece el servicio de renta de salvavidas, kayak, así como paseos en la banana y en lancha, entre otros. Se puede practicar el buceo y observar los corales, flor blanca, mantas, palmitas, estrellas de mar y peces multicolores.<br/><br/>
En Los Ayala también se pude acampar, sólo se le pide al turista que conserve limpio el lugar.<br/><br/>
Un domingo en Los Ayala es un gran recreo familiar.

            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
    <div className="first-des vision">
                <div className="des-text">
                    <h2></h2>
                    <p className="textos6">
                    En sus playas los niños juegan con sus inflables acompañados de sus padres o abuelos, jóvenes se divierten con un partido de fútbol en la playa, y adultos disfrutan de algún platillo típico como pescado zarandeado o ceviche en alguna de las palapas mientras escuchan las notas de algún corrido norteño.<br/><br/>
Los Ayala es uno de los tres pueblos conturbados que comprenden Rincon de Guayabitos, con una playa que se extiende por un kilómetro de dorada arena y templadas aguas turquesas rodeadas de hermosos riscos, una opción que garantiza diversión para todas las edades.<br/><br/>
Este pequeño poblado cercado por cerros verdes ha desarrollado una extensa variedad de hospedaje en los últimos cuatro años ofreciendo a sus visitantes opciones como acogedores hoteles y bungalows que brindan todas las comodidades necesarias y la mejor de las atenciones para que viva una agradable estancia.<br/><br/>

Además de la tranquilidad y tibieza de playas como Tortuga y Del Beso, en este destino tendrá la oportunidad de pasear en lancha a sitios como Isla del Coral o Isla Cangrejo, practicar al snorkel gracias a su formación rocosa, subirse a la banana o al Party Boat y bailar mientras navega por el Pacífico.<br/><br/>
Los Ayala cuenta con un clima muy favorable, así como la Bahía de Jaltemba que tiene 320 días soleados al año.<br/><br/>
Los Ayala y la Bahía de Jaltemba se encuentran a 72 km al norte de Puerto Vallarta.<br/><br/>
Venga a descansar y divertirse en Los Ayala, su estancia será regeneradora.

            </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>  
</div>
  )
}

export default Pagina7
