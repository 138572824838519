import React from 'react'
import Info2 from './Info2'
import Info6 from './Info6'
import Barra from "../Barra/Barra"
import QuienesImg from "../../assets/suites_barra.jpg"
import { slides } from "../data/carouselData.json"

const Room2 = () => {
  return (
    <div>
    <Barra
      cName="hero-mid"
      heroImg={QuienesImg}
      title=""        
      text=""
      btnClass="hide"/>
      <Info2/>
    </div>
  )
}

export default Room2
