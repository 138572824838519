import React from 'react'

import Barra from "../Barra/Barra"
import Pagina4 from "./Pagina4"
import QuienesImg from "../../assets/atractivos_barra.jpg"
const Lugar3 = () => {
  return (
    <>      
      <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
      <Pagina4/> 
    </>
  )
}

export default Lugar3
