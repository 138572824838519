import React from 'react'
import './Pagina8.css'

import Bil1 from "../../assets/07islacoral1.jpg";
import Bil2 from "../../assets/07islacoral2.jpg";
import Bil3 from "../../assets/07islacoral3.jpg";
import Bil4 from "../../assets/isladelcoral.jpg";

const Pagina8 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Isla del Coral</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos7">
            Uno de los mayores atractivos turísticos de esta región del pacífico mexicano, es una hermosa isla ubicada frente a Rincón de Guayabitos, Los Ayala y La Peñita de Jaltemba, a tan solo 10 minutos en lancha desde la costa, los prestadores de servicios turisticos ubicados en zona de playa ofrecen los paseos a la Isla del Coral, actualmente se encuentra en proceso de ser declarada como área natural protegida por la CONANP, Comisión Nacional de Áreas Naturales Protegidas, cuenta con muelle para el desembarco de turistas, quienes a su arribo observan la inigualable belleza de sus cristalinas aguas, sitio que tiene una amplia variedad de especies de flora y fauna, además de un acuario natural submarino impresionante al ser zona de arrecifes de coral; existiendo en la isla facilidades para la práctica del snorkel y buceo.
            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
    <div className="first-des vision">
                <div className="des-text">
                    <h2></h2>
                    <p className="textos7">
                    La playa de la Isla del Coral recibió a mediados del 2016 la Certificación como Playa Limpia, gracias al esfuerzo de los integrantes de la cooperativa organizada de la reserva ecológica, quienes de manera constante piden a los visitantes respetar a las especies que ahí habitan como pájaros bobos, gaviotas, pelícanos, entre otros; también se tiene desde la isla una vista impresionante a toda la Bahía de Jaltemba, ya que aproximadamente está ubicada a tres kilómetros de la costa nayarita.<br/><br/>
La certificación como Playa Limpia ha sido revalidada durante los últimos cinco años, desde 2016 hasta el 2020, obteniendo además en 2019 otra certificación de Playa Limpia Sustentable por parte de la SEMARNAT.

         </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>  
</div>
  )
}

export default Pagina8
