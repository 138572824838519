import React from 'react'
import './Pagina3.css'

import Bil1 from "../../assets/02MaleconLape_ita1.jpg";
import Bil2 from "../../assets/02MaleconLape_ita2.jpeg";
import Bil3 from "../../assets/02MaleconLape_ita3.jpg";
import Bil4 from "../../assets/02malecon-peñita4.jpg";

const Pagina3 = () => {
  return (
    <div className="destination">
    <p className="header-des encabe">Malecón La Peñita</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos2">
            Conocer el centro de la peñita es una obligación, también puedes caminar por su avenida principal en donde encontraras todo el comercio del pueblo y puedes disfrutar de la gastronomía que ofrecen como las famosas carretas de ceviche de pescado y camarones que encontraras en cada esquina de esta avenida.<br/><br/>
            </p>
            </div>
        <div className="image">
            <img alt="img" src={Bil1}/>
            <img alt="img2" src={Bil2}/>
        </div>
    </div>     
    <div className="first-des vision">
                <div className="des-text">
                    <h2>Clima </h2>
                    <p className="textos2">
                    La peñita es un destino de sol con más de 320 días soleados al año es el destino perfecto para los que quieren escapar a la playa y aprovechar de un buen clima.<br/><br/>
La temperatura en todo el año es de alrededor de 28 a 32 grados en los meses de verano y en invierno una mínima de 22 grados por lo que aun en invierno se considera un gran destina para escapar del frío.

            </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>  
</div>
  )
}

export default Pagina3
