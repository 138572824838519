import React from 'react'

import Barra from "../Barra/Barra"
import Pagina5 from "./Pagina5"
import QuienesImg from "../../assets/atractivos_barra.jpg"
const Lugar4 = () => {
  return (
    <>
     
     <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
      <Pagina5/>  
    </>
  )
}

export default Lugar4
