export const data = [
    {
        id: 1, imgUrl: "https://suitesdelcarmen.com.mx/suite6p_1.jpg"
    },
    {
        id: 2, imgUrl: "https://suitesdelcarmen.com.mx/suite6p_2.jpg"
    },
    {
        id: 3, imgUrl: "https://suitesdelcarmen.com.mx/suite6p_3.jpg"
    },
    {
        id: 4, imgUrl: "https://suitesdelcarmen.com.mx/suite6p_4.jpg"
    },
    {
        id: 5, imgUrl: "https://suitesdelcarmen.com.mx/suite6p_5.jpg"
    }
]