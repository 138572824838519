import React from 'react'
import Barra from '../Barra/Barra'
import Pagina6 from './Pagina6'
import QuienesImg from "../../assets/atractivos_barra.jpg"

const Lugar5 = () => {
  return (
    <>
      
     <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
        <Pagina6/>
    </>
  )
}

export default Lugar5
