import React from 'react'
import './app.css';
import Inicio from './Components/Routes/Inicio';
import Lugar1 from './Components/Routes/Lugar1';
import Lugar2 from './Components/Routes/Lugar2';
import Lugar3 from './Components/Routes/Lugar3';
import Lugar4 from './Components/Routes/Lugar4';
import Lugar5 from './Components/Routes/Lugar5';
import Lugar6 from './Components/Routes/Lugar6';
import Lugar7 from './Components/Routes/Lugar7';
import Lugar8 from './Components/Routes/Lugar8';
import Room1 from './Components/Routes/Room1';
import Room2 from './Components/Routes/Room2';
import Room3 from './Components/Routes/Room3';
import Room4 from './Components/Routes/Room4';
import Room5 from './Components/Routes/Room5';
import AreasComunes from "./Components/Lugares/AreasComunes"
import { Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Inicio/> }/>
        <Route path="/la-peñita-de-jaltemba" element={<Lugar1/> }/>
        <Route path="/malecon-la-peñita" element={<Lugar2/> }/>
        <Route path="/tianguis-la-peñita" element={<Lugar3/> }/>
        <Route path="/plaza-principal-la-peñita" element={<Lugar4/> }/>
        <Route path="/rincon-de-guayabitos" element={<Lugar5/> }/>
        <Route path="/playa-los-ayala" element={<Lugar6/> }/>
        <Route path="/isla-del-coral" element={<Lugar7/> }/>
        <Route path="/playa-mirador-del-toro" element={<Lugar8/> }/>
        <Route path="/habitacion-de-4" element={<Room1/>}/>
        <Route path="/habitacion-de-5" element={<Room2/>}/>
        <Route path="/habitacion-de-6" element={<Room3/>}/>
        <Route path="/habitacion-de-7" element={<Room4/>}/>
        <Route path="/habitacion-de-8" element={<Room5/>}/>
        <Route path="/areas-comunes" element={<AreasComunes/>}/>
      </Routes>
    </div>
  )
}

export default App
