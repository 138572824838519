import React from 'react'
import './Pagina2.css'

import Bil1 from "../../assets/01Lape_ita1.jpg";
import Bil2 from "../../assets/01La-Penita2.jpg";
import Bil3 from "../../assets/01La-Penita3.jpg";
import Bil4 from "../../assets/01La-Penita4.jpg";
import Bil5 from "../../assets/01lape_ita5.jpg";
import Barra from "../Barra/Barra"
import Img from "../../assets/atractivos_barra.jpg"

const Pagina2 = () => {
  return (
            <div className="destination">
            
            <p className="header-des encabe">La Peñita de Jaltemba</p>
            <div className="first-des-reverse mision">
                <div className="des-text">
                    <h2></h2>
                    <p className="textos1">
                    Nombre del pueblo más antiguo de la zona urbana, fue fundado por los antiguos mexicas (nahuatlacas) por su paso durante la peregrinación desde la Isla de Mexcaltitán hasta Tenochtitlán (hoy la Ciudad de México).<br/><br/>
Su nombre “Jaltemba”es una composición entre el español y náhuatl que se traduce como “Lugar a la orilla de la arena”. El agregado de “La Peñita” fue dado por los españoles en referencia a la Isla del Coral que aparenta ser una peña.<br/><br/>
La playa de este poblado es perfecta para disfrutar de los hermosos atardeceres, caminatas y la práctica del slideboard.<br/><br/>
Debido a su antigüedad, el lugar cuenta con pequeños hoteles y/o bungalows, casas de renta, diversos supermercados, restaurantes y una amplia variedad de comercios. El sitio cuenta con bares y discotecas por lo que en temporada baja su vida nocturna es más activa que el mismo Guayabitos.<br/><br/>
Un paisaje común al amanecer en la Peñita de Jaltemba es ver junto a las lanchas las redes de los pescadores tendidas sobre la arena, y cerca, decenas de pelícanos flotando apacibles sobre el mar.<br/><br/>
La Peñita es un destino cálido, con todo el encanto de un pequeño pueblo pesquero, que regala además a sus visitantes la tranquilidad y belleza de su entorno natural. No por nada este destino ha sido adoptado por muchos extranjeros como su hogar.
                     </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil1}/>
                    <img alt="img2" src={Bil2}/>
                </div>
            </div>      
            <div className="first-des vision">
                <div className="des-text">
                    <h2>Ubicación y Playas</h2>
                    <p className="textos1">
                    La Peñita de Jaltemba Nayarit es el más largo de los tres pueblos en la Bahía de Jaltemba, aproximadamente a 60 kilómetros al norte de Puerto Vallarta. La Bahía de Jaltemba abarca desde Punta Raza hasta Boca de Naranjo.<br/><br/>
Y al estar cerca de Guayabitos y Los Ayala, la tranquilidad de La Peñita se complementa con las opciones de diversión nocturna que se encuentran en los destinos vecinos.<br/><br/>
La población aproximada de La Peñita es de  9,100 residentes permanentes.<br/><br/>
Con un poco menos de 1.5 km, Playa La Peñita es la playa principal. Sus tranquilas aguas son perfectas para nadar y realizar deportes acuáticos. Y a solo a 5 minutos de distancia de La Peñita se encuentra Playa El Playón, una playa virgen ideal para caminar por la arena.<br/><br/>
Hoteles<br/><br/>
A pesar de ser un pequeño poblado, las opciones de hospedaje para que disfrute de sus tranquilas aguas y suave arena van desde pequeños hoteles hasta bungalows. Visite La Peñita y tal vez también quiera cambiar y establecer aquí su residencia.

            </p>
                    </div>
                <div className="image">
                    <img alt="img" src={Bil3}/>
                    <img alt="img2" src={Bil4}/>
                </div>
            </div>   
        </div>
  )
}

export default Pagina2
