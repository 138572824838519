import React from 'react'

import Bil1 from "../../assets/suite7p_1.jpg";
import Bil2 from "../../assets/suite7p_3.jpg";
import Galeria4 from './Galeria4';

const Info4 = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Habitacion para 7 personas</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            Esta amplia y acogedora habitación está diseñada para alojar cómodamente a grupos de hasta siete personas. El lujoso centro de atención es la cama king size, con sábanas suaves y almohadas mullidas, prometiendo un descanso reparador. Dos sofás cama matrimoniales ofrecen opciones adicionales para el alojamiento, brindando flexibilidad en la configuración y asegurando comodidad para todos los huéspedes.<br/><br/>

La habitación está decorada con elegancia y estilo, con colores relajantes que crean un ambiente tranquilo. Amplias ventanas permiten que la luz natural inunde la habitación, creando una sensación luminosa y acogedora. El acceso a Wi-Fi está disponible para que los huéspedes puedan mantenerse conectados durante su estancia.<br/><br/>

Un espacio de trabajo funcional con un escritorio y una silla ofrece un lugar para trabajar o estudiar, si es necesario. Además, un televisor de pantalla plana montado en la pared brinda opciones de entretenimiento para relajarse después de un día de actividades.<br/><br/>

En conjunto, esta habitación ofrece un amplio espacio, comodidad y conectividad para grupos más grandes, asegurando una estancia placentera y agradable para todos los huéspedes.
            </p>
            </div>
        <div className="margen">
          <Galeria4/>
        </div>
    </div>       
</div>
  )
}

export default Info4
