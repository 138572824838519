import React from 'react'
import QuienesImg from "../../assets/atractivos_barra.jpg"
import Barra from '../Barra/Barra'
import Pagina9 from './Pagina9'

const Lugar8 = () => {
  return (
    <>
      
     <Barra
        cName="hero-mid"
        heroImg={QuienesImg}
        title=""        
        text=""
        btnClass="hide"/>
        <Pagina9/>
    </>
  )
}

export default Lugar8
