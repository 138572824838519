import React from 'react'
import Galeria6 from "../Galerias/Galeria6"

const InfoAreas = () => {
  return (
    <div className="destination">
            
    <p className="header-des encabe">Áreas Comúnes</p>
    <div className="first-des-reverse mision">
        <div className="des-text">
            <h2></h2>
            <p className="textos1">
            
            Disfruta de las áreas comunes de nuestro hotel<br/><br/>

En nuestro hotel, no solo te ofrecemos habitaciones cómodas y limpias, sino también espacios comunes donde podrás relajarte y divertirte con tu familia o amigos. Contamos con una alberca climatizada donde podrás nadar o tomar el sol en cualquier época del año. También tenemos un patio con áreas para sombra, donde podrás leer un libro, tomar una siesta o disfrutar de la brisa. Si te gusta cocinar al aire libre, puedes usar nuestro asador y preparar una deliciosa parrillada. Y si quieres admirar la belleza del mar, te invitamos a subir a nuestra terraza con vista al mar en la parte alta del hotel, donde podrás ver el atardecer o las estrellas.<br/><br/>

No esperes más y reserva tu estancia en nuestro hotel. Te garantizamos que te sentirás como en casa y que disfrutarás de todas las comodidades que tenemos para ti. ¡Te esperamos! 😊

            </p>
            </div>
        <div className="margen">
          <Galeria6/>
        </div>
    </div>       
</div>
  )
}

export default InfoAreas
